import React from 'react'
import BattlewearIcon from "../../assets/collection-icons/battlewear.png"
import CountryIcon from "../../assets/collection-icons/country.png"
import oldIcon from "../../assets/collection-icons/old.png"
import WomenIcon from "../../assets/collection-icons/women.png"
import { useMediaQuery } from 'react-responsive'
import CollectionICON from "../../assets/collectioni-icon.png"
import './Collection.css'
import Header from '../../components/Header/Header'
import FavaouritesIcon from "../../assets/favourites.png"
import Allnfts from "../../assets/allnfts.png"
import Forsale from "../../assets/forsale.png"
import MultiRangeSlider from '../../components/MultiRangeSlider/MultiRangeSlider'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import NFT1 from '../../assets/collection/1.png'
import NFT2 from '../../assets/collection/2.png'
import NFT3 from '../../assets/collection/3.png'
import DiamondIcon from '../../assets/diamond.svg'
import SolanaIcon from '../../assets/solana.svg'

export default function Collection() {

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const isSmallScreen = useMediaQuery({ query: '(max-width: 760px)' })

    const [allNft, setAllNft] = React.useState(false)
    const [forSale, setForSale] = React.useState(false)
    const [favourites, setFavourites] = React.useState(false)

    const [sliderItems, setSliderItems] = React.useState([
        {
            id: 0,
            img: BattlewearIcon,
            text: "battlewear",
            selected: false
        },
        {
            id: 1,
            img: CountryIcon,
            text: "country",
            selected: true
        },
        {
            id: 2,
            img: oldIcon,
            text: "GOLDHEADS",
            selected: false
        },
        {
            id: 3,
            img: WomenIcon,
            text: "women",
            selected: false
        },
        {
            id: 4,
            img: BattlewearIcon,
            text: "battlewear",
            selected: false
        },
        {
            id: 5,
            img: CountryIcon,
            text: "country",
            selected: false
        }
    ]);

    const [selected, setSelected] = React.useState(sliderItems[1]);

    const selectionFunctions = (id) => {
        let items = [...sliderItems]

        items.map(item => {
            id === item.id ? item.selected = true : item.selected = false
        })

        console.log(items)
        setSliderItems([...items])
    }

    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: isSmallScreen ? 1 : isTabletOrMobile ? 3 : 4,
        slidesToScroll: 1,
    };

    const [traitSlider, setTraitSlider] = React.useState(0);

    const onTraitSlide = (event) => {
        setTraitSlider(event.target.value);
    }

    return (
        <div className='collectionScreenContainer'>
            <Header />
            <div className="collection-slider">
                <Slider {...settings}>
                    {
                        sliderItems.map(item => <div key={item.id} onClick={() => {
                            setSelected(item)
                            selectionFunctions(item.id)
                        }} className={item.selected ? "slider-item selected-slider mt-4" : "slider-item mt-4"}>
                            <img src={item.img} />
                            <p>{item.text}</p>
                        </div>)
                    }
                </Slider>
                <div className="collection-cover">
                    <div className="row">
                        <div className="nftdetails">
                            <img className="collection-avatar" src={selected.img} alt="battlewear_tokens" />
                            <div className="details">
                                <p className="collection-name">{selected.text}</p>
                                <div className="collection"><img src={CollectionICON} alt="collection-icons" /><p>SOLDIER COLLECTION</p></div>
                            </div>
                        </div>
                        <div className="nft-rates">
                            <div>
                                <p className="price">2.00</p>
                                <p className="action-text">BUY NOW</p>
                            </div>
                            <div>
                                <p className="price">1.50</p>
                                <p className="action-text">SELL NOW</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div>
                            <p className="value">350/4,418 (7.83%)</p>
                            <p className="label">LISTED/SUPPLY</p>
                        </div>
                        <div>
                            <p className="value">32</p>
                            <p className="label">VOLUME (24H)</p>
                        </div>
                        <div>
                            <p className="value">163,942</p>
                            <p className="label">VOLUME (ALL)</p>
                        </div>
                        <div>
                            <p className="value">6</p>
                            <p className="label">SALES (24H)</p>
                        </div>
                        <div>
                            <p className="value">-19.25%</p>
                            <p className="label">PRICE Δ (24H)</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className='collectionsContainer'>
                <div className='leftSide'>
                    <div className='filter'>
                        <p className='filterHead'>FILTER</p>
                        <div className='filterByChoice'>
                            <div onClick={() => setAllNft(!allNft)} className={allNft ? "selection selected" : "selection"}>
                                <img src={Allnfts} alt='allnfts' />
                                <p>ALL NFTS</p>
                            </div>
                            <div onClick={() => setForSale(!forSale)} className={forSale ? "selection selected" : "selection"}>
                                <img src={Forsale} alt='allnfts' />
                                <p>FOR SALE</p>
                            </div>
                            <div onClick={() => setFavourites(!favourites)} className={favourites ? "selection selected" : "selection"}>
                                <img src={FavaouritesIcon} alt='allnfts' />
                                <p>FAVOURITES</p>
                            </div>
                        </div>
                        <br />
                        <p className="filterHead fade">PRICE</p>
                        <MultiRangeSlider />
                        <br />
                        <br />
                        <p className="filterHead fade">RARITY</p>
                        <MultiRangeSlider />
                        <br />
                        <br />


                        <div className="rarity-tags">
                            <span className="tag-BA8AFF">LEGENDARY (5%)</span>
                            <span className="tag-FF8AAD">RARE (25%)</span>
                            <span className="tag-8EE3FB">UNCOMMON (50%)</span>
                            <span className="tag-BFC4CA">COMMON (100%)</span>
                        </div>

                        <br />
                        <br />
                        <p className="filterHead fade">TRAIT COUNT</p>
                        {/* <SingleRangeSlider min={0} max={10} /> */}
                        <input type="range" min="0" max="9" onChange={onTraitSlide} value={traitSlider} className="trait-slider" id="myRange" />
                        <div className="trait-slider-values">
                            <span>0</span>
                            <span>1</span>
                            <span>2</span>
                            <span>3</span>
                            <span>4</span>
                            <span>5</span>
                            <span>6</span>
                            <span>7</span>
                            <span>8</span>
                            <span>9</span>
                        </div>
                    </div>
                </div>
                <div className='rightSide'>
                    <div className='nftsCollection'>
                        {/*  */}
                        <div className='individualNFT'>
                            <img className='nftImage' src={NFT1} alt='NFT' />
                            <div className='nftFeatures'>
                                <div className='diamondValue'>
                                    <img src={DiamondIcon} alt='diamond_icon' />
                                    <span>2596</span>
                                </div>
                                <p>#424</p>
                            </div>
                            <div className='priceAndAction'>
                                <div className='currency'>
                                    <img src={SolanaIcon} alt='solana_icon' />
                                    <span className='price'>0.25</span>
                                </div>
                                <button>BUY</button>
                            </div>
                        </div>
                        {/*  */}
                        <div className='individualNFT'>
                            <img className='nftImage' src={NFT1} alt='NFT' />
                            <div className='nftFeatures'>
                                <div className='diamondValue'>
                                    <img src={DiamondIcon} alt='diamond_icon' />
                                    <span>2596</span>
                                </div>
                                <p>#424</p>
                            </div>
                            <div className='priceAndAction'>
                                <div className='currency'>
                                    <img src={SolanaIcon} alt='solana_icon' />
                                    <span className='price'>0.25</span>
                                </div>
                                <button>BUY</button>
                            </div>
                        </div>
                        {/*  */}
                        <div className='individualNFT'>
                            <img className='nftImage' src={NFT1} alt='NFT' />
                            <div className='nftFeatures'>
                                <div className='diamondValue'>
                                    <img src={DiamondIcon} alt='diamond_icon' />
                                    <span>2596</span>
                                </div>
                                <p>#424</p>
                            </div>
                            <div className='priceAndAction'>
                                <div className='currency'>
                                    <img src={SolanaIcon} alt='solana_icon' />
                                    <span className='price'>0.25</span>
                                </div>
                                <button>BUY</button>
                            </div>
                        </div>
                        {/*  */}
                        <div className='individualNFT'>
                            <img className='nftImage' src={NFT1} alt='NFT' />
                            <div className='nftFeatures'>
                                <div className='diamondValue'>
                                    <img src={DiamondIcon} alt='diamond_icon' />
                                    <span>2596</span>
                                </div>
                                <p>#424</p>
                            </div>
                            <div className='priceAndAction'>
                                <div className='currency'>
                                    <img src={SolanaIcon} alt='solana_icon' />
                                    <span className='price'>0.25</span>
                                </div>
                                <button>BUY</button>
                            </div>
                        </div>
                        {/*  */}
                        <div className='individualNFT'>
                            <img className='nftImage' src={NFT1} alt='NFT' />
                            <div className='nftFeatures'>
                                <div className='diamondValue'>
                                    <img src={DiamondIcon} alt='diamond_icon' />
                                    <span>2596</span>
                                </div>
                                <p>#424</p>
                            </div>
                            <div className='priceAndAction'>
                                <div className='currency'>
                                    <img src={SolanaIcon} alt='solana_icon' />
                                    <span className='price'>0.25</span>
                                </div>
                                <button>BUY</button>
                            </div>
                        </div>
                        {/*  */}
                        <div className='individualNFT'>
                            <img className='nftImage' src={NFT1} alt='NFT' />
                            <div className='nftFeatures'>
                                <div className='diamondValue'>
                                    <img src={DiamondIcon} alt='diamond_icon' />
                                    <span>2596</span>
                                </div>
                                <p>#424</p>
                            </div>
                            <div className='priceAndAction'>
                                <div className='currency'>
                                    <img src={SolanaIcon} alt='solana_icon' />
                                    <span className='price'>0.25</span>
                                </div>
                                <button>BUY</button>
                            </div>
                        </div>
                        {/*  */}
                        <div className='individualNFT'>
                            <img className='nftImage' src={NFT1} alt='NFT' />
                            <div className='nftFeatures'>
                                <div className='diamondValue'>
                                    <img src={DiamondIcon} alt='diamond_icon' />
                                    <span>2596</span>
                                </div>
                                <p>#424</p>
                            </div>
                            <div className='priceAndAction'>
                                <div className='currency'>
                                    <img src={SolanaIcon} alt='solana_icon' />
                                    <span className='price'>0.25</span>
                                </div>
                                <button>BUY</button>
                            </div>
                        </div>
                        {/*  */}
                        <div className='individualNFT'>
                            <img className='nftImage' src={NFT1} alt='NFT' />
                            <div className='nftFeatures'>
                                <div className='diamondValue'>
                                    <img src={DiamondIcon} alt='diamond_icon' />
                                    <span>2596</span>
                                </div>
                                <p>#424</p>
                            </div>
                            <div className='priceAndAction'>
                                <div className='currency'>
                                    <img src={SolanaIcon} alt='solana_icon' />
                                    <span className='price'>0.25</span>
                                </div>
                                <button>BUY</button>
                            </div>
                        </div>
                        {/*  */}
                        <div className='individualNFT'>
                            <img className='nftImage' src={NFT1} alt='NFT' />
                            <div className='nftFeatures'>
                                <div className='diamondValue'>
                                    <img src={DiamondIcon} alt='diamond_icon' />
                                    <span>2596</span>
                                </div>
                                <p>#424</p>
                            </div>
                            <div className='priceAndAction'>
                                <div className='currency'>
                                    <img src={SolanaIcon} alt='solana_icon' />
                                    <span className='price'>0.25</span>
                                </div>
                                <button>BUY</button>
                            </div>
                        </div>
                        {/*  */}
                        <div className='individualNFT'>
                            <img className='nftImage' src={NFT1} alt='NFT' />
                            <div className='nftFeatures'>
                                <div className='diamondValue'>
                                    <img src={DiamondIcon} alt='diamond_icon' />
                                    <span>2596</span>
                                </div>
                                <p>#424</p>
                            </div>
                            <div className='priceAndAction'>
                                <div className='currency'>
                                    <img src={SolanaIcon} alt='solana_icon' />
                                    <span className='price'>0.25</span>
                                </div>
                                <button>BUY</button>
                            </div>
                        </div>
                        {/*  */}
                        <div className='individualNFT'>
                            <img className='nftImage' src={NFT1} alt='NFT' />
                            <div className='nftFeatures'>
                                <div className='diamondValue'>
                                    <img src={DiamondIcon} alt='diamond_icon' />
                                    <span>2596</span>
                                </div>
                                <p>#424</p>
                            </div>
                            <div className='priceAndAction'>
                                <div className='currency'>
                                    <img src={SolanaIcon} alt='solana_icon' />
                                    <span className='price'>0.25</span>
                                </div>
                                <button>BUY</button>
                            </div>
                        </div>
                        {/*  */}
                        <div className='individualNFT'>
                            <img className='nftImage' src={NFT1} alt='NFT' />
                            <div className='nftFeatures'>
                                <div className='diamondValue'>
                                    <img src={DiamondIcon} alt='diamond_icon' />
                                    <span>2596</span>
                                </div>
                                <p>#424</p>
                            </div>
                            <div className='priceAndAction'>
                                <div className='currency'>
                                    <img src={SolanaIcon} alt='solana_icon' />
                                    <span className='price'>0.25</span>
                                </div>
                                <button>BUY</button>
                            </div>
                        </div>
                        {/*  */}
                        <div className='individualNFT'>
                            <img className='nftImage' src={NFT1} alt='NFT' />
                            <div className='nftFeatures'>
                                <div className='diamondValue'>
                                    <img src={DiamondIcon} alt='diamond_icon' />
                                    <span>2596</span>
                                </div>
                                <p>#424</p>
                            </div>
                            <div className='priceAndAction'>
                                <div className='currency'>
                                    <img src={SolanaIcon} alt='solana_icon' />
                                    <span className='price'>0.25</span>
                                </div>
                                <button>BUY</button>
                            </div>
                        </div>
                        {/*  */}
                        <div className='individualNFT'>
                            <img className='nftImage' src={NFT1} alt='NFT' />
                            <div className='nftFeatures'>
                                <div className='diamondValue'>
                                    <img src={DiamondIcon} alt='diamond_icon' />
                                    <span>2596</span>
                                </div>
                                <p>#424</p>
                            </div>
                            <div className='priceAndAction'>
                                <div className='currency'>
                                    <img src={SolanaIcon} alt='solana_icon' />
                                    <span className='price'>0.25</span>
                                </div>
                                <button>BUY</button>
                            </div>
                        </div>
                        {/*  */}
                        <div className='individualNFT'>
                            <img className='nftImage' src={NFT1} alt='NFT' />
                            <div className='nftFeatures'>
                                <div className='diamondValue'>
                                    <img src={DiamondIcon} alt='diamond_icon' />
                                    <span>2596</span>
                                </div>
                                <p>#424</p>
                            </div>
                            <div className='priceAndAction'>
                                <div className='currency'>
                                    <img src={SolanaIcon} alt='solana_icon' />
                                    <span className='price'>0.25</span>
                                </div>
                                <button>BUY</button>
                            </div>
                        </div>
                        {/*  */}
                        <div className='individualNFT'>
                            <img className='nftImage' src={NFT1} alt='NFT' />
                            <div className='nftFeatures'>
                                <div className='diamondValue'>
                                    <img src={DiamondIcon} alt='diamond_icon' />
                                    <span>2596</span>
                                </div>
                                <p>#424</p>
                            </div>
                            <div className='priceAndAction'>
                                <div className='currency'>
                                    <img src={SolanaIcon} alt='solana_icon' />
                                    <span className='price'>0.25</span>
                                </div>
                                <button>BUY</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
